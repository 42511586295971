<template>
  <div
    class="card mb-5"
    :class="isLoading ? 'sec-saving' : ''"
  >
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('legalSigner.generalInformation.title') }}
          </h4>
        </div>
        <cp-edit-buttons
          v-if="!isSecIdKycProvider"
          :edit-mode="isDetailsEditableCurrentBlock"
          :disable-edit="!canViewEditInvestorInfo"
          :tooltip-message="this.$t('legalSigner.generalInformation.editTooltipMessage')"
          :show-tooltip="!canViewEditInvestorInfo"
          :is-loading="isLoading"
          @changeMode="changeModeProxy"
          @update="saveChanges"
        />
      </div>
    </div>
    <div class="card-body">
      <cp-input-container
        ref="cpInputContainer"
        v-model="model"
        :default-value="legalSignerGeneralInfo"
      >
        <div class="mb-4 bg-light full-width">
          <div class="col-md-4">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td>{{ $t('legalSigner.generalInformation.inputs.signerType') }}</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      v-model="model.signerType"
                      disabled
                      :options="signerTypeList"
                      name="signerType"
                      @input="callConfirmModal"
                    />
                    <strong v-else>
                      {{ legalSignerGeneralInfo.signerType }}
                    </strong>
                  </td>
                </tr><!--investorType-->
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <template v-if="isEntity">
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.name') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.legalName"
                        :disabled="!canViewEditInvestorInfo"
                        name="legalName"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.legalName }}
                      </strong>
                    </td>
                  </tr><!--Name-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.email') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.email"
                        name="email"
                        validate="required|email"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.email }}
                      </strong>
                    </td>
                  </tr><!--Email-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.dba') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.businessName"
                        :disabled="!canViewEditInvestorInfo"
                        name="businessName"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.businessName }}
                      </strong>
                    </td>
                  </tr><!--entityDba-->
                </template>
                <template v-if="isIndividual">
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.firstName') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.individualName.firstName"
                        name="firstName"
                        :disabled="!canViewEditInvestorInfo"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.individualName.firstName }}
                      </strong>
                    </td>
                  </tr><!--firstName-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.middleName') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.individualName.middleName"
                        :disabled="!canViewEditInvestorInfo"
                        name="middleName"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.individualName.middleName }}
                      </strong>
                    </td>
                  </tr><!--middleName-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.lastName') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.individualName.lastName"
                        name="lastName"
                        :disabled="!canViewEditInvestorInfo"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.individualName.lastName }}
                      </strong>
                    </td>
                  </tr><!--lastName-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.email') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.email"
                        name="email"
                        validate="required|email"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.email }}
                      </strong>
                    </td>
                  </tr><!--email-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.birthdate') }}</td>
                    <td>
                      <cp-date-picker
                        v-if="isDetailsEditableCurrentBlock"
                        name="birthDate"
                        initial-view="year"
                        :open-date="openDate"
                        :disabled="!canViewEditInvestorInfo"
                        :disabled-dates="disabledDates"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.birthDate | dateFilter(!canViewEditInvestorInfo) }}
                      </strong>
                    </td>
                  </tr><!--birthdate-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.taxCountry') }}</td>
                    <td>
                      <cp-select
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.taxCountry"
                        :options="countryList"
                        name="taxCountry"
                      />
                      <strong v-else>
                        {{ getCurrentTaxCountryName('taxCountry') | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--taxCountry-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.taxId') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.taxId"
                        name="taxId"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.taxId }}
                      </strong>
                    </td>
                  </tr><!--taxId-->
                </template>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <template v-if="isEntity">
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.taxId') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.taxId"
                        name="taxId"
                        :disabled="!canViewEditInvestorInfo"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.taxId | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--taxId-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.type') }}</td>
                    <td>
                      <cp-select
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.entityType"

                        :options="entityTypeList"
                        name="entityType"
                        validate="required"
                      />
                      <strong v-else>
                        {{ $t(`securitizeId.entityType["${legalSignerGeneralInfo.entityType}"]`) || legalSignerGeneralInfo.entityType }}
                      </strong>
                    </td>
                  </tr><!--entityType-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.ultimateBeneficialOwner') }}</td>
                    <td>
                      <b-form-checkbox
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.ultimateBeneficialOwner"
                        name="ultimateBeneficialOwner"
                        class="visibility-checkbox"
                      >
                        <span>{{ $t('legalSigner.generalInformation.inputs.ultimateBeneficialOwner') }}</span>
                      </b-form-checkbox>
                      <strong v-else>
                        {{ legalSignerGeneralInfo.ultimateBeneficialOwner }}
                      </strong>
                    </td>
                  </tr>
                </template>
                <template v-if="isIndividual">
                  <tr>
                    <td>
                      {{ $t('legalSigner.generalInformation.inputs.country') }}
                    </td>
                    <td>
                      <cp-select
                        v-if="isDetailsEditableCurrentBlock"
                        key="countryCode"
                        v-model="model.address.countryCode"
                        :options="countryList"
                        name="countryCode"
                        validate="required"
                      />
                      <strong v-else>
                        {{ getCurrentCountryName('countryCode') | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--countryCode-->
                  <tr v-if="model.address.countryCode === 'US'">
                    <td>
                      {{ $t('legalSigner.generalInformation.inputs.state') }}
                    </td>
                    <td>
                      <cp-select
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.address.state"
                        :options="stateList"
                        :disabled="!canViewEditInvestorInfo"
                        name="state"
                        validate="required"
                      />
                      <strong v-else>
                        {{ generalDetailState | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--state-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.city') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.address.city"
                        name="city"
                        :disabled="!canViewEditInvestorInfo"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.address.city | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--city-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.postalCode') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.address.zip"
                        name="postalCode"
                        :disabled="!canViewEditInvestorInfo"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.address.zip | nullToMinus }}
                      </strong>
                    </td>
                  </tr><!--postalCode-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.streetName') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.address.street"
                        name="streetName"
                        :disabled="!canViewEditInvestorInfo"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.address.street }}
                      </strong>
                    </td>
                  </tr><!--streetName-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.streetNumber') }}</td>
                    <td>
                      <cp-input
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.address.streetNumber"
                        name="streetNumber"
                        :disabled="!canViewEditInvestorInfo"
                        validate="required"
                      />
                      <strong v-else>
                        {{ legalSignerGeneralInfo.address.streetNumber }}
                      </strong>
                    </td>
                  </tr><!--streetNumber-->
                  <tr>
                    <td>{{ $t('legalSigner.generalInformation.inputs.ultimateBeneficialOwner') }}</td>
                    <td>
                      <b-form-checkbox
                        v-if="isDetailsEditableCurrentBlock"
                        v-model="model.ultimateBeneficialOwner"
                        name="ultimateBeneficialOwner"
                        class="visibility-checkbox"
                      >
                        <span>{{ $t('legalSigner.generalInformation.inputs.ultimateBeneficialOwner') }}</span>
                      </b-form-checkbox>
                      <strong v-else>
                        {{ legalSignerGeneralInfo.ultimateBeneficialOwner }}
                      </strong>
                    </td>
                  </tr><!--ultimateBeneficialOwner-->
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </cp-input-container>
    </div>
    <cp-confirm-modal
      ref="cpConfirmModal"
      title="Change investor type"
      ok-only
      @onOk="$emit('confirm')"
    >
      {{ $t('legalSigner.generalInformation.note') }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import states from '@/utilities/us-states-list';
import { entityTypeList, signerTypeList } from './options';
import { CpDatePicker, CpSelect, CpInput } from '@/components/common/standalone-components/inputs';
import cpInputContainer from '@/components/common/cpInputContainer';
import CpEditableMixin from '~/mixins/editable-invester-details-component';
import CpEditButtons from '~/components/common/edit-buttons';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'CpLegalSignerGeneralInformation',
  components: {
    cpInputContainer,
    CpDatePicker,
    CpSelect,
    CpInput,
    CpConfirmModal,
    CpEditButtons,
  },
  mixins: [
    CpEditableMixin(),
  ],
  props: {
    isSecIdKycProvider: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      states,
      entityTypeList,
      signerTypeList,
      userId: this.$route.params.detailsId,
      issuerId: this.$route.params.idIssuer,
      legalSignerId: this.$route.params.legalSignerId,
      disabledDates: {
        from: new Date(moment()
          .subtract(15, 'years')),
      },
      openDate: new Date('1990-01-01'),
      model: {},
    };
  },
  computed: {
    ...mapState('investors', ['legalSignerGeneralInfo']),
    ...mapGetters('global', ['countries']),
    ...mapGetters('issuersInfo', ['permissionsPerIssuer']),
    canViewEditInvestorInfo() {
      return this.permissionsPerIssuer.canOperatorViewEditInvestorInfo;
    },
    countryList() {
      return (this.countries || []).map(item => ({
        text: item.name,
        value: item.countryCode,
      }));
    },
    stateList() {
      return Object.keys(this.states)
        .map(val => ({ text: this.states[val], value: val }));
    },
    stateOptions() {
      return Object.keys(this.states).map(val => ({ text: this.states[val], value: val }));
    },
    generalDetailState() {
      const currentState = this.stateOptions.find(el => el.value === this.model.address.state);
      return currentState ? currentState.text : null;
    },
    isIndividual() {
      return this.model.signerType === 'individual';
    },
    isEntity() {
      return this.model.signerType === 'entity';
    },
  },
  async created() {
    this.changeMode(true);
    this.model = JSON.parse(JSON.stringify(this.legalSignerGeneralInfo));
  },
  methods: {
    ...mapActions('investors', ['updateLegalSignerById', 'getLegalSignerById']),
    changeModeProxy() {
      this.model = JSON.parse(JSON.stringify(this.legalSignerGeneralInfo));
      this.changeMode();
    },
    getCurrentCountryName(field) {
      const country = this.countries.find(item => item.countryCode === this.legalSignerGeneralInfo.address[field]);
      return country ? country.name : null;
    },
    getCurrentTaxCountryName(field) {
      const country = this.countries.find(item => item.countryCode === this.legalSignerGeneralInfo[field]);
      return country ? country.name : null;
    },
    getUltimateBeneficialOwner(field) {
      return field === 1 ? 'true' : 'false';
    },
    getUltimateBeneficialOwnerValue() {
      return !!this.legalSignerGeneralInfo.ultimateBeneficialOwner;
    },
    onCheckedCheckBox(val) {
      const checkedBoxValue = val === false ? 0 : 1;
      this.model.ultimateBeneficialOwner = checkedBoxValue;
    },
    saveChanges() {
      if (this.model.countryCode !== 'US') this.model.state = undefined;
      this.$refs.cpInputContainer.validateForm()
        .then(() => {
          if (this.$refs.cpInputContainer.isValid()) {
            this.isLoading = true;
            this.updateLegalSignerById({
              userId: this.userId,
              issuerId: this.issuerId,
              legalSignerId: this.legalSignerId,
              legalSigner: this.model,
            })
              .then(() => {
                const origin = this.isSecuritizeIdOrigin ? 'securitize-id' : 'issuer';
                this.getLegalSignerById({ params: { userId: this.userId, issuerId: this.issuerId, legalSignerId: this.legalSignerId, origin } })
                  .finally(() => {
                    this.isLoading = false;
                    this.changeModeProxy();
                  });
              });
          }
        });
    },
    callConfirmModal() {
      this.$refs.cpConfirmModal.show();
    },
  },
};
</script>
<style scoped>
    .full-width {
        margin: -24px;
    }
</style>
