var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-5",class:_vm.isLoading ? 'sec-saving' : ''},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-between"},[_c('h4',{staticClass:"mb-0 text-primary"},[_vm._v(" "+_vm._s(_vm.$t('legalSigner.documents.title'))+" ")]),(!_vm.isSecIdKycProvider)?_c('cp-button',{attrs:{"icon":"ios-add","variant":"primary","disabled":!_vm.canViewEditInvestorInfo},on:{"click":_vm.showAddLegalSignerDocumentModal}},[_vm._v(" "+_vm._s(_vm.$t('legalSigner.documents.addDocumentButton'))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"table-responsive"},[_c('b-table',{staticClass:"card-table",attrs:{"items":_vm.legalSignerGeneralInfo.documents,"url-params":_vm.queryParams,"fields":_vm.documentsFields},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"ui-w-40 d-block link",staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.getImageIcon(item),"alt":_vm.$t('legalSigner.documents.imageAlt')},on:{"click":function($event){return _vm.showPreviewDocModal(item)}}})]}},{key:"docType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDocType(item.docType))+" ")]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(item.createDate))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('cp-button',{attrs:{"icon":"ios-arrow-round-down","variant":"outline-primary","disabled":_vm.downloadingDocuments[item.documentId] || !_vm.canViewEditInvestorInfo,"is-loading":_vm.downloadingDocuments[item.documentId]},on:{"click":function($event){return _vm.downloadDocument(item)}}},[_vm._v(" "+_vm._s(_vm.$t('legalSigner.documents.downloadButton'))+" ")]),(!_vm.isSecIdKycProvider && _vm.canViewEditInvestorInfo)?_c('cp-button',{attrs:{"icon":"ion ion-ios-trash","variant":"default ml-3","disabled":_vm.documentsCount < 2},on:{"click":function($event){return _vm.showDeleteModal(item)}}},[(_vm.documentsCount < 2)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],attrs:{"title":(_vm.documentsCount < 2) ? _vm.$t('legalSigner.documents.note'):''}},[_vm._v(" "+_vm._s(_vm.$t('legalSigner.documents.deleteButton'))+" ")]):_vm._e(),(_vm.documentsCount > 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('legalSigner.documents.deleteButton'))+" ")]):_vm._e()]):_vm._e()],1)]}}])})],1),(!_vm.isSecIdKycProvider)?_c('add-legal-signer-document-modal',{ref:"addLegalSignerDocumentModal",attrs:{"categories":_vm.categories,"disabled":!_vm.canViewEditInvestorInfo},on:{"legalSignerDocumentAdded":_vm.refreshTable}}):_vm._e(),_c('preview-legal-signer-document-modal',{ref:"previewLegalSignerDocModal",attrs:{"document-id":_vm.documentId,"is-sec-id-kyc-provider":_vm.isSecIdKycProvider,"file-type":_vm.fileType}}),_c('cp-confirm-modal',{ref:"cpConfirmModal",attrs:{"title":_vm.$t('legalSigner.documents.removeModal.title')},on:{"onOk":_vm.deleteLegalSignerDocument}},[_vm._v(" "+_vm._s(_vm.confirmModalText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }