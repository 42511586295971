<template>
  <div v-if="isReady">
    <div class="row align-items-center mb-4">
      <div class="col-md-8">
        <b-button
          class="btn btn-outline-primary btn-sm"
          @click="goToInvestorDetails"
        >
          <i class="ion ion-ios-arrow-back" />
          {{ $t('legalSigner.backButton') }}
        </b-button>
      </div>
      <div class="col-md-4 d-md-flex justify-content-end">
        <span class="text-muted" />
      </div>
    </div>

    <div class="row align-items-end mb-2 row-overwrite justify-content-between">
      <div class="py-3">
        <h4 class="font-weight-bold">
          {{ $t('legalSigner.editButton') }}
        </h4>
      </div>
    </div>
    <cp-legal-signer-general-information
      :is-sec-id-kyc-provider="isSecIdKycProvider"
    />
    <cp-legal-signer-documents
      :is-sec-id-kyc-provider="isSecIdKycProvider"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CpLegalSignerGeneralInformation from './components/legal-signer-general-information';
import CpLegalSignerDocuments from './components/documents';
import routeNames from '~/utilities/routes';

export default {
  name: 'CpLegalSigner',
  components: {
    CpLegalSignerGeneralInformation,
    CpLegalSignerDocuments,
  },
  data() {
    return {
      issuerId: this.$route.params.idIssuer,
      userId: this.$route.params.detailsId,
      tokenId: this.$route.params.tokenId,
      legalSignerId: this.$route.params.legalSignerId,
      isReady: false,
    };
  },
  computed: {
    ...mapGetters('investors', ['getDetailKyc']),
    isSecIdKycProvider() {
      return this.getDetailKyc.kycProvider === 'securitize-id';
    },
  },
  async created() {
    const origin = this.isSecIdKycProvider ? 'securitize-id' : 'issuer';
    await this.getLegalSignerById({ params: { userId: this.userId, issuerId: this.issuerId, legalSignerId: this.legalSignerId, origin } });
    this.isReady = true;
  },
  methods: {
    ...mapActions('investors', ['getLegalSignerById']),
    goToInvestorDetails() {
      this.$router.push(routeNames.viewDetails(this.issuerId, this.tokenId, this.userId));
    },
  },
};
</script>
