<template>
  <b-modal
    ref="uploadDocument"
    :title="$t('legalSigner.addLegalSignerDocumentModal.title')"
    @show="clearModal"
    @ok="sendDocument"
  >
    <cp-file-uploader
      id="investor-document-uploader"
      :document-error="documentError"
      :data-check="checkDockSize"
      :file-rules="checkDockSize"
      :max-file-size="maxDocSize"
      class-style-override="small-footprint"
      show-default-message
      @onChange="fileUploaded"
    />
    <hr>
    <cp-select
      v-model="documentTypeModel"
      placeholder
      :label="$t('legalSigner.addLegalSignerDocumentModal.input.category')"
      :options="documentTypes"
    />

    <template #modal-footer="{ cancel, ok }">
      <b-button
        @click="cancel"
      >
        {{ $t('legalSigner.addLegalSignerDocumentModal.cancelButton') }}
      </b-button>
      <cp-button
        size="md"
        :disabled="okDisabled"
        variant="primary"
        :is-loading="saving"
        @click="ok"
      >
        {{ $t('legalSigner.addLegalSignerDocumentModal.addLegalSignerDocumentButton') }}
      </cp-button>
    </template>
  </b-modal>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';

export default {
  name: 'AddLegalSignerDocumentModal',
  components: {
    CpSelect,
    CpFileUploader,
    CpButton,
  },
  props: {
    categories: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      newFile: {},
      maxDocSize: 50,
      queryparams: {
        issuerId: this.$route.params.idIssuer,
        userId: this.$route.params.detailsId,
        legalSignerId: this.$route.params.legalSignerId,
      },
      fileKey: '',
      entityDocumentData: {
        side: 'front',
      },
      documentTypeModel: '',
      individualDocumentData: {
        side: 'front',
        docType: 'passport',
        docCategory: 'signer-identification',
      },
      documentError: false,
      investorId: this.$route.params.investorId,
      legalSignerId: this.$route.params.legalSignerId,
    };
  },
  computed: {
    ...mapState('investors', ['legalSignerGeneralInfo']),
    documentTypes() {
      if (this.legalSignerGeneralInfo.signerType === 'entity') {
        return this.legalSignerGeneralInfo.entityType
          ? this.categories.documentsTypeEntity[this.legalSignerGeneralInfo.entityType] : [];
      }
      return this.categories.documentTypeIndividual;
    },
    okDisabled() {
      return Boolean(!this.documentTypeModel.docType || this.fileKey === '');
    },
  },
  methods: {
    ...mapActions('investors', ['createLegalSignerDocument']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    checkDockSize({ size }) {
      return size > 10 * 10000000; // If more than 10 MB, reject
    },
    fileUploaded({ file, fileKey }) {
      if (!fileKey) this.onUploadError(file);
      this.documentError = false;
      this.newFile = file;
      this.fileKey = fileKey;
    },
    onUploadError(file) {
      let errorText = 'Upload Error';
      if (file && file.status === 'error') {
        if (file.xhr && file.xhr.responseText) {
          errorText = file.xhr.responseText;
        }
      }
      this.showErrorMessage(errorText);
    },
    showErrorMessage(error) {
      this.CALL_ERROR_TOASTER(error);
      this.$log.error('Legal signer add document upload file error:', error);
    },
    clearModal() {
      this.newFile = {};
      this.documentError = false;
    },
    sendDocument(env) {
      env.preventDefault();
      if (!this.newFile.accepted) {
        this.documentError = true;
        return;
      }
      if (!this.newFile) {
        this.CALL_ERROR_TOASTER('Please upload the document');
        return;
      } if (!this.newFile.accepted) {
        this.CALL_ERROR_TOASTER('File must be between 20KB and 50MB');
        return;
      }
      this.saving = true;
      if (this.legalSignerGeneralInfo.signerType === 'entity') this.document = { ...this.document, ...this.entityDocumentData };
      if (this.legalSignerGeneralInfo.signerType === 'individual') this.document = { ...this.document, ...this.individualDocumentData };
      this.document = {
        ...this.document,
        ...this.documentTypeModel,
      };
      this.createLegalSignerDocument({
        ...this.queryparams,
        document: {
          fileKey: this.fileKey,
          fileName: this.newFile.name,
          ...this.document,
        },
      }).then(() => {
        this.$refs.uploadDocument.hide();
        this.$emit('legalSignerDocumentAdded');
      }).finally(() => {
        this.saving = false;
      });
    },
  },
};
</script>
