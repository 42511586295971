<template>
  <div
    class="card mb-5"
    :class="isLoading ? 'sec-saving' : ''"
  >
    <!--Documents header-->
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-12 d-flex justify-content-between">
          <h4 class="mb-0 text-primary">
            {{ $t('legalSigner.documents.title') }}
          </h4>
          <cp-button
            v-if="!isSecIdKycProvider"
            icon="ios-add"
            variant="primary"
            :disabled="!canViewEditInvestorInfo"
            @click="showAddLegalSignerDocumentModal"
          >
            {{ $t('legalSigner.documents.addDocumentButton') }}
          </cp-button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <b-table
        :items="legalSignerGeneralInfo.documents"
        :url-params="queryParams"
        :fields="documentsFields"
        class="card-table"
      >
        <template
          slot="image"
          slot-scope="{item}"
        >
          <img
            class="ui-w-40 d-block link"
            :src="getImageIcon(item)"
            style="cursor: pointer"
            :alt="$t('legalSigner.documents.imageAlt')"
            @click="showPreviewDocModal(item)"
          >
        </template>
        <template
          slot="docType"
          slot-scope="{ item }"
        >
          {{ getDocType(item.docType) }}
        </template>
        <template
          slot="createdAt"
          slot-scope="{item}"
        >
          {{ item.createDate | dateFilter }}
        </template>
        <template
          slot="actions"
          slot-scope="{item}"
        >
          <div class="d-flex">
            <cp-button
              icon="ios-arrow-round-down"
              variant="outline-primary"
              :disabled="downloadingDocuments[item.documentId] || !canViewEditInvestorInfo"
              :is-loading="downloadingDocuments[item.documentId]"
              @click="downloadDocument(item)"
            >
              {{ $t('legalSigner.documents.downloadButton') }}
            </cp-button>
            <cp-button
              v-if="!isSecIdKycProvider && canViewEditInvestorInfo"
              icon="ion ion-ios-trash"
              variant="default ml-3"
              :disabled="documentsCount < 2"
              @click="showDeleteModal(item)"
            >
              <span
                v-if="documentsCount < 2"
                v-b-tooltip.hover="true"
                v-b-tooltip.hover.left
                :title="(documentsCount < 2) ? $t('legalSigner.documents.note'):''"
              >
                {{ $t('legalSigner.documents.deleteButton') }}
              </span>
              <span
                v-if="documentsCount > 1"
              >
                {{ $t('legalSigner.documents.deleteButton') }}
              </span>
            </cp-button>
          </div>
        </template>
      </b-table>
    </div>

    <add-legal-signer-document-modal
      v-if="!isSecIdKycProvider"
      ref="addLegalSignerDocumentModal"
      :categories="categories"
      :disabled="!canViewEditInvestorInfo"
      @legalSignerDocumentAdded="refreshTable"
    />

    <preview-legal-signer-document-modal
      ref="previewLegalSignerDocModal"
      :document-id="documentId"
      :is-sec-id-kyc-provider="isSecIdKycProvider"
      :file-type="fileType"
    />

    <cp-confirm-modal
      ref="cpConfirmModal"
      :title="$t('legalSigner.documents.removeModal.title')"
      @onOk="deleteLegalSignerDocument"
    >
      {{ confirmModalText }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import documents from './options';

import PreviewLegalSignerDocumentModal from './modals/preview-legal-signer-document-modal';
import CpButton from '~/components/common/standalone-components/cp-button';
import AddLegalSignerDocumentModal from './modals/add-legal-signer-document-modal';
import staticPathGeneration from '~/mixins/static-path-generation';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'CpLegalSignerDocuments',
  components: {
    CpButton,
    AddLegalSignerDocumentModal,
    PreviewLegalSignerDocumentModal,
    CpConfirmModal,
  },
  mixins: [staticPathGeneration],
  props: {
    isSecIdKycProvider: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      queryParams: {
        issuerId: this.$route.params.idIssuer,
        userId: this.$route.params.detailsId,
        legalSignerId: this.$route.params.legalSignerId,
      },
      documentId: null,
      fileType: '',
      downloadingDocuments: {},
      deleteItem: null,
      isLoading: false,
      documentsFields: documents.fields,
      categories: documents.categories,
      documentTypes: documents.documentTypes,
      confirmModalText: this.$t(
        'investorDetails.removeModal.message',
        [this.$t('investorDetails.documents.label.document')],
      ),
      model: [],
    };
  },
  computed: {
    ...mapState('investors', ['legalSignerGeneralInfo']),
    ...mapGetters('issuersInfo', ['permissionsPerIssuer']),
    canViewEditInvestorInfo() {
      return this.permissionsPerIssuer.canOperatorViewEditInvestorInfo;
    },
    documentsCount() {
      return this.legalSignerGeneralInfo ? this.legalSignerGeneralInfo.documents.length : 0;
    },
    getImageIcon() {
      return ({ thumbnail, fileType }) => (
        fileType === 'application/pdf'
          ? this.getImagePath('fallback-pdf.jpg')
          : thumbnail || this.getImagePath('fallback-image.jpg')
      );
    },
  },
  methods: {
    ...mapActions('investors', [
      'getLegalSignerDocumentById',
      'deleteLegalSignerDocumentById',
      'getLegalSignerById',
    ]),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    async refreshTable() {
      this.isLoading = true;
      const origin = this.isSecIdKycProvider ? 'securitize-id' : 'issuer';
      await this.getLegalSignerById({ params: { ...this.queryParams, origin } });
      this.isLoading = false;
    },
    showDeleteModal({ documentId }) {
      this.deleteItem = documentId;
      this.$refs.cpConfirmModal.$refs.confirmModal.show();
    },
    showPreviewDocModal({ documentId, fileType }) {
      if (this.canViewEditInvestorInfo) {
        this.documentId = documentId;
        this.fileType = fileType;
        this.$refs.previewLegalSignerDocModal.$refs.previewDocument.show();
      }
    },
    showAddLegalSignerDocumentModal() {
      this.$refs.addLegalSignerDocumentModal.$refs.uploadDocument.show();
    },
    deleteLegalSignerDocument() {
      this.deleteLegalSignerDocumentById({
        ...this.queryParams,
        legalSignerDocumentId: this.deleteItem,
      }).then(this.refreshTable);
    },
    downloadDocument({ documentId }) {
      this.downloadingDocuments[documentId] = true;
      const origin = this.isSecIdKycProvider ? 'securitize-id' : 'issuer';
      this.getLegalSignerDocumentById({
        legalSignerDocumentId: documentId,
        ...this.queryParams,
        origin,
      }).then(
        ({ url }) => {
          if (url) {
            // to easy download file, create downloadable link and click it
            const link = document.createElement('a');
            link.target = '_blank';
            link.download = url.split('/').pop();
            link.name = url.split('/').pop();
            link.href = url;
            link.click();
          } else {
            this.CALL_ERROR_TOASTER(this.$t('investorDetails.documents.message.downloadFailure'));
          }
        },
      ).finally(() => {
        delete this.downloadingDocuments[documentId];
      });
    },
    changeModeProxy() {
      if (this.legalSignerGeneralInfo) {
        this.model = this.legalSignerGeneralInfo.documents
          .map(({ documentId, verificationStatus }) => ({ documentId, verificationStatus }));
        this.changeMode();
      }
    },
    getDocType(docType) {
      return this.documentTypes[docType];
    },
    findType(types, type) {
      const find = ({ value }) => value.docType === type;
      const found = types.find(find);
      if (!found) return type;
      return found.text;
    },
  },
};
</script>
<style scoped>
    .sec-saving::after{
      content:"";
      position: absolute;
      width:100%;
      height:100%;
      background:#fff;
      opacity: 0.4;
    }
</style>
