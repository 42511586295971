export default {
  documentTypes: {
    'document-or-certificate-of-trust': 'The full trust document or certificate of trust',
    'list-of-trustees': 'List of trustees',
    'partnership-agreement': 'Partnership agreement',
    'lp-list-and-authorised-signers-list': 'LP list and authorized signers list',
    'articles-of-organization': 'Articles of organization',
    'operating-agreement': 'Operating agreement',
    'members-and-authorised-signers-list': 'Members\'s list and authorized signers list',
    'by-laws': 'By-laws',
    'certificate-of-formation': 'Certificate of formation',
    'articles-of-corporation': 'Articles of corporation',
    'shareholders-list-and-authorised-signers-list': 'Shareholder\'s list and authorized signers list',
    other: 'Other',
    passport: 'Passport',
    'driver-license': 'Driver License',
    'national-identity-card': 'National ID card',
    selfie: 'Selfie',
  },
  categories: {
    documentTypeIndividual: [
      { value: { docCategory: 'signer-identification', docType: 'passport' }, text: 'Passport' },
      { value: { docCategory: 'signer-identification', docType: 'driver-license' }, text: 'Driver License' },
      { value: { docCategory: 'signer-identification', docType: 'national-identity-card' }, text: 'National ID card' },
      { value: { docCategory: 'signer-identification', docType: 'other' }, text: 'Proof of address' },
      { value: { docCategory: 'signer-identification', docType: 'selfie' }, text: 'Selfie' },
      { value: { docCategory: 'signer-identification', docType: 'other' }, text: 'Other' },
    ],
    documentsTypeEntity: {
      'revocable-trust': [
        { value: { docCategory: 'signer-entity', docType: 'document-or-certificate-of-trust' }, text: 'The full trust document or certificate of trust' },
        { value: { docCategory: 'signer-entity', docType: 'list-of-trustees' }, text: 'List of trustees' },
      ],
      'irrevocable-trust': [
        { value: { docCategory: 'signer-entity', docType: 'document-or-certificate-of-trust' }, text: 'The full trust document or certificate of trust' },
        { value: { docCategory: 'signer-entity', docType: 'list-of-trustees' }, text: 'List of trustees' },
      ],
      'limited-general-partnership': [
        { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of formation' },
        { value: { docCategory: 'signer-entity', docType: 'partnership-agreement' }, text: 'Partnership agreement' },
        { value: { docCategory: 'signer-entity', docType: 'lp-list-and-authorised-signers-list' }, text: 'LP list and authorized signers list' },
      ],
      llc: [
        { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of formation' },
        { value: { docCategory: 'signer-entity', docType: 'articles-of-organization' }, text: 'Articles of organization' },
        { value: { docCategory: 'signer-entity', docType: 'operating-agreement' }, text: 'Operating agreement' },
        { value: { docCategory: 'signer-entity', docType: 'members-and-authorised-signers-list' }, text: 'Members\'s list and authorized signers list' },
      ],
      corporation: [
        { value: { docCategory: 'signer-entity', docType: 'by-laws' }, text: 'By-laws' },
        { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of formation' },
        { value: { docCategory: 'signer-entity', docType: 'articles-of-corporation' }, text: 'Articles of corporation' },
        { value: { docCategory: 'signer-entity', docType: 'shareholders-list-and-authorised-signers-list' }, text: 'Shareholder\'s list and authorized signers list' },
      ],
      other: [
        { value: { docCategory: 'signer-entity', docType: 'other' }, text: 'Other' },
      ],
    },
  },
  fields: [
    { key: 'image', label: 'Thumbnail' },
    { key: 'docType', label: 'Document Type', tdClass: 'align-middle' },
    { key: 'createdAt', label: 'Creation Date', tdClass: 'align-middle' },
    {
      key: 'actions', label: '', i18nKey: 'investorDetails.documents.label.table.empty', tdClass: 'text-nowrap d-inline float-right',
    },
  ],
};
