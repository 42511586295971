export const entityTypeList = [
  { value: 'revocable-trust', i18nKey: 'common.category.entityType.revocableTrust' },
  { value: 'irrevocable-trust', i18nKey: 'common.category.entityType.irrevocableTrust' },
  { value: 'limited-general-partnership', i18nKey: 'common.category.entityType.limitedGeneralPartnership' },
  { value: 'llc', i18nKey: 'common.category.entityType.llc' },
  { value: 'corporation', i18nKey: 'common.category.entityType.corporation' },
  { value: 'joint-account', i18nKey: 'common.category.entityType.jointAccount' },
  { value: 'other', i18nKey: 'common.category.entityType.other' },
];

export const signerTypeList = [
  {
    value: 'individual',
    i18nKey: 'common.category.investorType.individual',
  },
  {
    value: 'entity',
    i18nKey: 'common.category.investorType.entity',
  },
];
